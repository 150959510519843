<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-28 16:30:02
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-01-24 22:09:13
-->
<template>
  <div class="page-box">
    <list @load="getList">
      <div class="order" v-for="(item, index) in list" :key="index">
        <order-card
          :showDetailBtn="false"
          :info="item"
          :btns="[
            { text: '删除', method: 'del' },
            { text: '继续报修', type: 'primary', method: 'repairAgain' },
          ]"
          @onclickbtn="clickBtn"
        />
      </div>
    </list>

    <msg-box v-model="showNone" @onclick="$router.back()" />
  </div>
</template>

<script>
import orderCard from "../../../components/OrderCard/OrderCard";
import { Toast, Dialog, List } from "vant";
import pageLoader from "../../../utils/load_per_page";
import msgBox from "../../../components/msgBox/msgBox";
import {
  draftBoxList,
  delDraftBox,
  submit,
} from "../../../utils/api/workOrder";
export default {
  components: {
    "order-card": orderCard,
    list: List,
    "msg-box": msgBox,
  },
  data() {
    return {
      listLoader: null,
      list: [],

      showNone: false, // 显示没有列表
    };
  },
  created() {},

  methods: {
    // 获取列表
    getList() {
      if (!this.listLoader)
        this.listLoader = new pageLoader(
          draftBoxList,
          "data.data.list.data",
          true,
          "data.data.list.last_page"
        );
      this.listLoader
        .load({
          status: this.$route.query.status,
          month:
            this.$route.query.status == 4 ? `${this.year}-${this.month}` : "",
        })
        .then((res) => {
          if (res && res.length === 0) this.showNone = true;
          if (res && res.length) {
            this.showNone = false;
            this.list = res;
          }
        })
        .catch((res) => {});
    },

    // 重新获取列表
    reGetList() {
      this.listLoader.reLoad();
      this.getList();
    },

    // 点击按钮
    clickBtn(e) {
      this[e.detail.method](e.info.id);
    },

    // 删除
    del(id) {
      Dialog.confirm({
        title: "提示",
        message: "确认删除?",
      })
        .then(() => {
          delDraftBox({
            draftBoxId: id,
          })
            .then((res) => {
              Toast.success("删除成功");
              this.reGetList();
            })
            .catch((res) => {
              Toast(res.data.msg);
            });
        })
        .catch(() => {
          // on cancel
        });
    },

    // 继续报修
    repairAgain(id) {
      submit({
        draftBoxIds: JSON.stringify([id]),
      }).then((res) => {
        Toast.success("报修成功");
        this.reGetList();
      });
    },
  },
};
</script>

<style  src="../../../assets/css/user/waitReview.css" scoped>
</style>