import { render, staticRenderFns } from "./DraftBox.vue?vue&type=template&id=fd7c2626&scoped=true&"
import script from "./DraftBox.vue?vue&type=script&lang=js&"
export * from "./DraftBox.vue?vue&type=script&lang=js&"
import style0 from "../../../assets/css/user/waitReview.css?vue&type=style&index=0&id=fd7c2626&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd7c2626",
  null
  
)

export default component.exports